

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/mariage-divorce-services-avocat-droit-famille-quebec.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost43 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Mariage et divorce : les services de l’avocat en droit de la famille - Soumissions Avocat"
        description="S’il est vrai que le mariage a longtemps eu la cote au Québec pour des raisons culturelles et religieuses, la réalité d’aujourd’hui est que beaucoup de Québécois choisissent de vivre en Union de fait. Cela ne signifie toutefois pas que le mariage est devenu une institution obsolète, au contraire! Encore LIRE PLUS"
        image={LeadImage}>
        <h1>Mariage et divorce : les services de l’avocat en droit de la famille</h1>

					
					
						<p>S’il est vrai que le mariage a longtemps eu la cote au Québec pour des raisons culturelles et religieuses, la réalité d’aujourd’hui est que beaucoup de Québécois choisissent de vivre en Union de fait. Cela ne signifie toutefois pas que le mariage est devenu une institution obsolète, au contraire! Encore des milliers de Québécois décident de consacrer ultimement leur amour chaque année par les liens sacrés de ce type d’union.</p>
<p><StaticImage alt="mariage-divorce-services-avocat-droit-famille-quebec" src="../images/mariage-divorce-services-avocat-droit-famille-quebec.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Malheureusement, les statistiquement révèlent, d’un autre côté, un taux de succès un peu moins enthousiastes.  Le nombre de divorce est en effet relativement élevé, ce qui crée une foule de questionnements juridiques pour les individus impliqués. Nous vous présentons donc les services qu’un avocat en droit de la famille peut vous offrir.</p>
<h2>Pourquoi consulter un avocat avant de se marier?</h2>
<p>Bien qu’il soit peu romantique de recourir aux services d’un avocat avant de se marier, il s’agit d’une initiative qui ne protège pas seulement l’intérêt en cas de divorce, mais qui assure également la validité de l’union nouvellement formée. En effet, le mariage est une forme de « contrat » qui nécessite le respect de certains critères pour être validement formé. Un avocat peut donc vous conseiller sur les points suivants.</p>
<p><strong>Informations sur le régime juridique en place.</strong> Le mariage comporte des droits, des obligations ainsi que des devoirs pour les deux époux. Notamment, ils se doivent chacun respect, secours, fidélité et assistance. De plus, le mariage a pour effet de créer un régime matrimonial ainsi qu’un patrimoine familial. Vous verrez que vous avez intérêt à connaître les bases de la loi en matière matrimoniale avant de promettre votre amour éternel.</p>
<p><strong>Aide dans le choix du régime matrimonial. </strong>La composition du patrimoine familial et des biens en faisant partie est déjà prévu par la loi et en peut en aucun cas être modifié par un contrat. Cependant, le régime matrimonial est laissé au libre choix des époux entre les 3 options prévues par la loi. L’avocat consulté de sera un plaisir de vous présenter le fonctionnement de chacun d’entre eux. <strong> </strong></p>
<p>Conseils sur le respect des formalités de célébration. La célébration d’un mariage au Québec nécessite la lecture de quelques articles de loi et celle-ci doit être menée par un célébrant compétent. C’est le Code civil du Québec qui régit ces mêmes règles et qui énumère les différents célébrants compétents.</p>
<p><strong>Vérification du respect des conditions d’éligibilité. </strong>Le gouvernement fédéral prévoit des conditions bien précises pour qu’une personne soit en droit de se marier. Un mariage formé en contradiction avec ces lois pourrait rendre celui nul de nullité absolue, ce qui ferait en sorte que le mariage n’a jamais existé juridiquement.</p>
<h2>Comment faire le bon choix de régime matrimonial?</h2>
<p>Tel que mentionné, les époux doivent choisir lequel des trois régimes matrimoniaux prévus par la loi régira leur administration des biens lors du mariage et la séparation à la fin de l’union, advenant le cas. Si vous décidez de ne pas faire de choix, la loi prévoit que c’est le régime de la société d’acquêts qui s’appliquera à votre union. Si un tel scénario ne vous convient pas, examinez de plus près les autres options ouvertes à vous.</p>
<p><StaticImage alt="choix-regime-matrimonial-societe-acquetc-separation-biens-avocats" src="../images/choix-regime-matrimonial-societe-acquetc-separation-biens-avocats.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Société d’acquêts : </strong>Le régime matrimonial de la société d’acquêts fait une distinction entre les biens propres et les biens acquêts. Ces premiers sont des biens qui appartiennent à chacun des conjoints individuellement et dont la valeur n’est pas partagée au moment du divorce (ex : Héritages, dons, biens datant d’avant l’union, etc.)</p>
<p>En revanche, les biens acquêts sont ceux qui appartiennent aux deux conjoints en dehors de ceux faisant partie du patrimoine familial et qui sont partagés à la fin de l’union. Durant le mariage, chacun des conjoints a le plein contrôle sur ses biens propres et ils administrent en commun les biens acquêts.</p>
<p><strong>
Séparation de biens : </strong>Pour les époux souhaitant conserver une pleine indépendance financière durant le mariage (à l’exclusion du patrimoine familial), puisque chacun des conjoints garde le plein contrôle et la pleine propriété sur ses biens. Ainsi, chacun administre ses propres biens durant le mariage et repart avec ce qui lui appartient à la dissolution de celui-ci. Ce régime n’entraîne donc pas de partage.</p>
<p><strong>
Communauté de biens :</strong> Régime un peu plus complexe que les deux précédents, la communauté de biens était l’option par défaut avant l’année 1970. Ce régime consiste à diviser les biens entre les biens propres du mari et de la femme, les biens communs (qui appartiennent et qui sont administrés par les deux) ainsi que les biens réservés.</p>
<p>La particularité de ce régime se situe surtout au niveau du partage lors duquel la femme décide d’accepter ou de refuser la communauté de biens.  Lorsque la femme accepte la communauté de biens, une division égalitaire de la valeur des biens communs et réservés est faite entre les époux. Advenant le cas contraire, l’épouse repart avec ses biens réservés et le mari avec les biens communs.</p>
<h2>Qu’est-ce que le patrimoine familial?</h2>
<p>Que ce soit le mari ou la femme qui soit le principal pourvoyeur de la famille ou que les deux rapportent des salaires semblables, la loi protège les époux en cas de divorce. On reconnait effectivement que l’apport à une vie de couple peut se faire autrement que financièrement—comme en élevant une famille—ce qui peut justifier une compensation financière lors de la fin de l’union. C’est pourquoi le patrimoine existe. Il rassemble quatre catégories de biens qui dont la valeur (et non la propriété elle-même) devra être partagée.</p>
<p><strong>Les véhicules de la famille : </strong>Seront considérés comme des véhicules de la famille ceux qui servent aux déplacements de ses membres au quotidien. Il est plus facile de prouver qu’un véhicule sert à la famille que le contraire. Par contre, des véhicules récréatifs utilisés par un seul des conjoints, comme un VTT ou un autre engin du genre, ne sauraient entrer dans le patrimoine familial.</p>
<p><strong>
Les meubles de la maison : </strong>L’ensemble des meubles faisant partie du ménage feront l’objet d’un inventaire et leur valeur sera calculée. Bien que la pratique veuille qu’on les sépare également, c’est plutôt leur valeur qui sera divisée en deux. Le véritable propriétaire pourra retenir les meubles s’ils lui appartiennent, mais devra tout de même verser une créance équivalente à la moitié de la valeur à son ex-conjoint.</p>
<p><strong>
Les résidences familiales :</strong> Tout comme les meubles, ce n’est pas la propriété des résidences familiales qui est divisée, mais la valeur de celles-ci. Si c’est l’épouse qui est la propriétaire, elle aura le droit de garder la maison, mais elle devra verser la moitié de la valeur à l’ex-conjoint.</p>
<p><strong>
Les régimes de retraite durant l’union :</strong> Les rentes gouvernementales ou les cotisations à des régimes de retraite vont faire partie du patrimoine familial, à l’exception de celles contractées avant le mariage entre les deux conjoints.<strong>
</strong>
Il n’y a rien qu’un avocat puisse faire pour changer les règles du patrimoine familial, car même un contrat entre les parties sur la modification de celui-ci est invalide si l’un des conjoints change d’idée. Il est seulement possible de renoncer au patrimoine. Un avocat peut toutefois être utile pour s’assurer que la division de ce patrimoine soit faite dans les règles de l’art.</p>
<h2>Les services de l’avocat lors du divorce</h2>
<p>Vous songez à divorcer et vous vous demandez quels sont les principaux services de l’avocat en droit du divorce? Le travail des avocats travaillant dans ce domaine consiste autant à régler rapidement le divorce qu’à concilier les parties lorsqu’il s’agit de leur volonté. Voici donc leur offre de service en droit de la famille et du divorce.</p>
<ul>
<li><strong>Médiation familiale </strong></li>
<li><strong>Garde d’enfants </strong></li>
<li><strong>Etablissement de la pension </strong></li>
<li><strong>Partage des régimes </strong></li>
<li><strong>Présentation du projet de divorce à l’amiable </strong></li>
</ul>
<h2>La pension alimentaire : qui devra la payer à qui?</h2>
<p>Il existe deux types de pensions alimentaires en droit québécois. Il y a celle que l’on verse à son ex-conjoint lorsqu’il ou elle a la garde principale des enfants ainsi que celle qui vise à assurer la stabilité financière de ce conjoint lui-même.</p>
<p><StaticImage alt="versement-pension-alimentaire-enfants-conjoint-avocat-quebec" src="../images/versement-pension-alimentaire-enfants-conjoint-avocat-quebec.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>En effet, la loi cherche assurer un certain maintien des conditions de vie retrouvées lors du mariage même après que celui-ci soit terminé pour laisser le temps à ce conjoint de redresser sa situation financière. Il faudra toutefois tenir compte de plusieurs critères pour fixer le montant à verser.</p>
<p><strong>Critères pris en compte :</strong></p>
<ul>
<li><strong>La garde des enfants.</strong></li>
<li><strong>La situation financière de chacun des conjoints </strong></li>
<li><strong>Le rôle de chacun des conjoints dans l’union </strong></li>
<li><strong>Le montant de la pension pour enfants </strong></li>
<li><strong>La durée de l’union </strong></li>
</ul>
<p>Prenez également note que lorsque deux pensions alimentaires sont demandées (une pour l’enfant et une autre pour le conjoint), et que le conjoint débiteur n’a les moyens que d’en payer une des deux, c’est celle destinée à l’enfant qui prime sur l’autre.</p>
<h2>Est-ce que toutes les raisons sont bonnes pour divorcer?</h2>
<p>Si vous et votre époux ou épouse songez à divorcer, vous devez savoir que ce ne sont pas toutes les raisons qui sont bonnes pour le faire. La loi n’accepte que certains motifs précis pour prononcer un jugement de divorce. Vous devez donc entrer dans l’une de ces grandes catégories au moment de présenter la demande :</p>
<p><strong>Cruauté physique ou psychologique de la part de l’un des époux.
</strong>
<strong>Adultère de l’un des conjoints.
</strong>
<strong>Absence de vie commune pendant 1 an :</strong></p>
<p><strong>Annulation de mariage et divorce : quelles sont les différences concrètes entre les deux?</strong> Ne confondez surtout pas la demande en divorce avec la demande en annulation de mariage. Le divorce vise à mettre un terme au mariage, alors que l’annulation vise à faire comme si celui-ci n’avait jamais eu lieu. Un recours en annulation prétend donc que le consentement de l’un des époux était vicié ou que les conditions de fond n’étaient pas respectées.</p>
<h2>Trouvez un avocat en droit de la famille sans tarder avec Soumissions Avocat!</h2>
<p>Le divorce est une expérience assez pénible en soi, rien ne sert de tourner le fer dans la plaie en éternisant les procédures. Faites immédiatement affaires avec Soumissions Avocat pour trouver un avocat en droit du divorce, peu importe où vous vous trouvez dans la province de Québec</p>
<p><strong>Il suffit de remplir le formulaire et, dans les plus brefs délais, nous acheminerons votre demande à 3 avocats près de chez vous!</strong></p>
<p><strong>Qu’attendez-vous pour régler vos problèmes avec les meilleurs avocats quand notre plateforme est gratuite et que nos services efficaces!</strong></p>
    </SeoPage>
)
export default BlogPost43
  